import React, { useState } from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);
  return (
    <LessonLayout
      current_step={5}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 5</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>In the Stelara® commercial that you watched earlier, can you find information about both benefits and risks of the medication? Try to find 3 benefits and 3 risks. Record your ideas in your science notebook. </p>
            <p>Click on the “Transcript” button to see the written version of the commercial. Click the “Video” button if you want to watch the commercial again. </p>

            <div className="flex flex-col">
              <Button
                action="secondary"
                color="purple"
                href="https://www.ispot.tv/ad/ASVF/stelara-which-you"
                icon="external"
                title="Video"
              >
                Video
              </Button>
              <Button
                action="secondary"
                className="mt-5"
                color="purple"
                href="/files/hs/lesson_4/stelara_commercial_transcript.pdf"
                icon="download"
                title="Transcript"
              >
                Transcript
              </Button>
            </div>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Students should identify 3 benefits and 3 risks identified in the Stelara® commercial. Some of the benefits may be worded in ways that imply a generally more healthy, positive outcome (for example, being able to wear a dress that exposes your skin) rather than in medical terms. Encourage students to talk with 1–2 other students as they do this.</p>
            <p>The goal is to help students see that an ad for a prescription medication cannot be slanted to focus only on benefits (this will come out later in the lesson). Click to see a sample analysis of benefits and risks from the commercial.</p>

            <Button
              action="secondary"
              color="purple"
              href="/files/hs/lesson_4/stelara_transcript_sample_analysis.pdf"
              icon="download"
              title="Sample analysis of benefits and risks"
            >
              Sample analysis of benefits and risks
            </Button>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

